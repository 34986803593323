/*
------------------------------------------------------------------
SCSS Variables
------------------------------------------------------------------
*/
html {
  box-sizing: border-box;
}

* {
  -webkit-overflow-scrolling: touch;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background-color: #ffffff;
  color: #444444;
  font-family: helvetica-w01-light, helvetica-w02-light, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*
------------------------------------------------------------------
General Styles
------------------------------------------------------------------
*/
h1,
h3,
h4,
h5,
h6 {
  color: #444444;
  font-family: helvetica-w01-light, helvetica-w02-light, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  margin: 0;
}

body:not(.content) h2 {
  color: #444444;
  font-family: helvetica-w01-light, helvetica-w02-light, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
}

h1 {
  font-size: 30px;
  line-height: 1.1em;
}

#content_page_wrapper h2 {
  font-weight: bold;
}

a {
  outline: none;
  color: #444444;
  text-decoration: none;
  font-style: normal;
  font-weight: normal;
}

a:hover {
  color: #444444;
  text-decoration: underline;
  font-style: normal;
}

img {
  height: auto;
}

.container {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.container.show-container {
  opacity: 1;
}




/*
------------------------------------------------------------------
Gallery Styles
------------------------------------------------------------------
*/
body.gallery .assets-grid-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
}

/*
------------------------------------------------------------------
Contact
------------------------------------------------------------------
*/
.contact-page-wrapper {
  color: #444444;
  font-family: helvetica-w01-light, helvetica-w02-light, sans-serif;
  line-height: 1.7 !important;
  font-size: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
}

@media (max-width:960px) {
  .contact-page-wrapper {
    padding-left: 0px;
    height: 349px;
    margin-top: 60px;
    min-width: 517px;
  }
}

@media (min-width:960px) {
  .contact-page-wrapper {
    padding-left: 290px;
    height: 100vh;
    margin-top: 0px;
    min-width: 517px;

  }
}

.social-link a:not(.social-share) {
  height: 22px;
  width: 22px;
  text-align: center;
  border-radius: 50%;
  display: block;
  padding: 3px;
}
.social-link a {
  color: #777777;
  font-family: helvetica-w01-light, helvetica-w02-light, sans-serif;
  font-size: 12px;
  font-weight: normal;
  text-decoration: none;
}
.mail-link{
  display:block;
  background: url(images/mail.png) no-repeat;
  background-size: 27px 27px, cover;
  padding: 0 0 0 35px;
}

.insta-link{
  display:block;
  background: url(images/insta.png) no-repeat;
  background-size: 27px 27px, cover;
  padding: 0 0 0 35px;
}