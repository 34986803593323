img.gallery-element-mobile{
    width: 100%;
}
img.gallery-element{
    height: 95vh;
}
.assets-grid-wrapper {
    overflow-x: auto;
    overflow-y: hidden;
}
.assets-grid {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    position: relative;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    margin-left: 0;
    transition: margin-left 500ms ease;
}

.assets-grid .asset {
    align-items: center;
    margin: 0px 15px;
    position: relative;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
}

.assets-grid-mobile .asset {
    height: auto;
    margin: 15px 0px;
}