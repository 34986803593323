/*
------------------------------------------------------------------s
Desktop Header / Menu Styles
------------------------------------------------------------------
*/
.header:not(.mobile-menu) {
  display: none;
  background-color: rgba(255, 255, 255, 0);
  position: fixed;
  top: 0;
  z-index: 2;
  height: 95vh;
  width: auto;
  padding: 0 40px;
  left: 0px;
}

.header:not(.mobile-menu).show-header {
  display: block;
}

.header:not(.mobile-menu) .menu-outer {
  display: flex;
  flex-direction: column;
  height: 95vh;
  justify-content: center;
  width: 200px;
}

.header:not(.mobile-menu) .menu-outer .menu-inner {
  height: 95vh;
  display: flex;
  flex-direction: column;
}

.header:not(.mobile-menu) .menu-outer .menu-inner .menu ul a {
  margin-bottom: 15px;
}

.header:not(.mobile-menu) .menu-outer .menu-inner {
  overflow: auto;
  height: 95vh;
}

.header:not(.mobile-menu) .header-logo-container {
  position: relative;
}

.header:not(.mobile-menu) .logo {
  color: #000000;
  font-family: helvetica-w01-light, helvetica-w02-light, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  margin: 20px 20px 30px 0;
}

.header:not(.mobile-menu) .logo a {
  color: #000000;
  font-family: helvetica-w01-light, helvetica-w02-light, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  display: block;
  padding: 1em 0 0;
}

.header:not(.mobile-menu) .logo img {
  max-height: 173px;
  height: calc(20px + 66px);
}

@media only screen and (min-width: 850px) {
  .header:not(.mobile-menu) .logo img {
    position: fixed;
    opacity: 0;
  }
}

.header:not(.mobile-menu) .logo.overflow img {
  position: absolute;
  height: auto;
  max-width: 100%;
  opacity: 1;
}

.header:not(.mobile-menu) .header-items-container {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.header:not(.mobile-menu) .header-items-container .social {
  margin: 10px 0 20px 0;
}

.header:not(.mobile-menu) .header-items-container::-webkit-scrollbar {
  -webkit-appearance: none;
}

.header:not(.mobile-menu) .header-items-container::-webkit-scrollbar:vertical {
  width: 7px;
}

.header:not(.mobile-menu) .header-items-container::-webkit-scrollbar:horizontal {
  height: 11px;
}

.header:not(.mobile-menu) .header-items-container::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.5);
}

/*
  ------------------------------------------------------------------s
  Mobile Header / Menu Styles
  ------------------------------------------------------------------
  */
.header.mobile-menu {
  z-index: 10;
  position: fixed;
  width: 100vw;
  top: 0;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition: transform 500ms ease;
}

.header.mobile-menu.hidden {
  -webkit-transform: translate3d(0, -100%, 0);
  -ms-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}

.header.mobile-menu .header-logo-container {
  position: relative;
  background-color: #ffffff;
}

.header.mobile-menu .logo {
  color: #000000;
  font-family: helvetica-w01-light, helvetica-w02-light, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  margin: 0 70px 0 0;
}

.header.mobile-menu .logo a {
  color: #000000;
  font-family: helvetica-w01-light, helvetica-w02-light, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  padding: 20px;
  display: inline-block;
}

.header.mobile-menu .logo img {
  opacity: 0;
  display: block;
  max-height: 60px;
  height: calc(20px + 66px);
}

.header.mobile-menu .logo.overflow img {
  opacity: 1;
  height: auto;
  max-width: 100%;
}

.header.mobile-menu .header-items-container {
  background-color: #ffffff;
  height: 0;
  opacity: 0;
  -webkit-transition: opacity 300ms ease;
  transition: opacity 300ms ease;
  pointer-events: none;
}

.header.mobile-menu .header-items-container .menu-links {
  text-align: center;
}

.header.mobile-menu.active {
  overflow: auto;
}

.header.mobile-menu.active .header-items-container {
  display: block;
  overflow: auto;
  opacity: 1;
  pointer-events: auto;
  color:#000000;
  margin-top: 0px;
  height: 522px;
}

.header.mobile-menu.active .header-items-container .menu {
  display: block;
}

.header.mobile-menu .menu-links .item a,
.header.mobile-menu .menu-links .category a {
  padding: 12px 8px;
  line-height: 21px;
  text-align: center;
}

.header.mobile-menu .menu-links .category>a {
  font-size: 14px;
}

.legacy-ios .header.mobile-menu {
  max-width: 100%;
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
 .hamburger {
  position: absolute;
  top: 50%;
  right: 20px;
  padding: 0;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: -12.5px 0 0;
  overflow: visible;
  outline: none;
}

@media only screen and (min-width: 850px) {
  .hamburger {
    display: none;
  }
}

.hamburger:hover {
  opacity: 0.7;
}

.hamburger.is-active:hover {
  opacity: 0.7;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #444444;
}

.hamburger-box {
  width: 25px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 25px;
  height: 4px;
  background-color: #444444;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -8px;
}

.hamburger-inner::after {
  bottom: -8px;
}

.hamburger .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger .hamburger-inner::before {
  transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}

.hamburger .hamburger-inner::after {
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger.is-active .hamburger-inner {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}

.hamburger.is-active .hamburger-inner::after {
  bottom: 0;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

body.menu-active {
  overflow: hidden;
}

.menu li>a {
  font-size: 18px;
  line-height: 21px;
  text-decoration: none;
  width: auto;
  padding: 0;
  color: #444444;
  background: #ffffff;
  font-family: helvetica-w01-light, helvetica-w02-light, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 2;
  background-color: rgba(0, 0, 0, 0);
}

.menu li.item>a:hover {
  background: #ffffff;
  color: #ff3a40;
  font-style: normal;
  font-weight: normal;
  background-color: rgba(0, 0, 0, 0);
}

.menu li.item.selected a {
  background: #ffffff;
  color: #ff3a40;
  font-style: normal;
  font-weight: normal;
  background-color: rgba(0, 0, 0, 0);
}

.menu li.category>a {
  color: #777777;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
}

.menu li.category>a:hover {
  color: #ff3a40;
}

.menu .menu-links {
  margin: 0;
}

.menu ul {
  list-style: none;
  padding-left: 0;
}

.menu ul a {
  display: inline-block;
  outline: none;
}

.menu ul>.item a,
.menu ul>.item span,
.menu ul>.item svg,
.menu ul .category a,
.menu ul .category span,
.menu ul .category svg {
  -webkit-transition: opacity 500ms ease, top 500ms ease;
  transition: opacity 500ms ease, top 500ms ease;
  position: relative;
  top: 20px;
  opacity: 0;
}

.menu ul>.item.show-link a,
.menu ul>.item.show-link span,
.menu ul>.item.show-link svg,
.menu ul .category.show-link a,
.menu ul .category.show-link span,
.menu ul .category.show-link svg {
  top: 0px;
  opacity: 1;
}

.menu ul.fade-links>* {
  transition: opacity 300ms ease;
  -webkit-transition: opacity 300ms ease;
}

.menu ul.fade-links:hover>* {
  opacity: 0.5;
}

.menu ul.fade-links>*:hover {
  opacity: 1;
}

.menu .category {
  cursor: pointer;
  overflow: hidden;
}

.menu .category .category-list {
  transition: height 180ms ease-in-out, opacity 180ms ease;
}

.menu .category .category-list.collapsed {
  opacity: 0;
}

.menu .category .category-link {
  border-top: 1px solid #eaeaea;
  width: 100%;
  padding: 0.4em 0;
}

.menu .category .category-link.list_end_cat,
.menu .category .category-link.category-open {
  border-bottom: 1px solid #eaeaea;
  margin: 0 0 4px 0;
}

.menu .item {
  margin-bottom: 3px;
}